import React, { useState } from 'react'
import { graphql } from 'gatsby'

import { Box, Container, Grid, Typography } from '@mui/material';
import Breadcrumbs from '../components/Breadcrumbs';
import RichText from '../components/RichText';
import Content from '../components/Content';

import ContactForm from '../components/forms/ContactForm';
import Map from '../components/Map';
import PinIcon from '../components/icons/PinIcon';
import Seo from '../components/Seo';
import buildMetaData from '../services/buildMetaData';
import buildOgpTags from '../services/buildOgpTags';
import addToSchema from '../services/addToSchema';


const ContactPageTemplate = ({ data, pageContext }) => {
  const page = data.page || {}

  const locations = data?.locations?.edges.map(({ node }) => node) || {}

  const [currentLocation, setCurrentLocation] = useState(locations[0])

  const onChangeLocation = (location) => {
    setCurrentLocation(location)
  }

  return <>
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <RichText
            sx={{
              textAlign: 'center'
            }}
            content={page.data.title.richText}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>

              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <ContactForm />
                </Grid>
                {locations.map(location => <Grid item key={`location-${location.id}`}>
                  <Box
                    onClick={() => onChangeLocation(location)}
                    sx={{
                      borderRadius: '10px',
                      WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                      border: '1px solid #ccc',
                      borderColor: currentLocation.id === location.id ? '#CE2227' : '#ccc',
                      height: 80,
                      paddingLeft: 4,
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        borderColor: '#CE2227'
                      }
                    }}>
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}>
                      <Grid item>
                        <PinIcon sx={{ fontSize: '35px' }} />
                      </Grid>
                      <Grid item>
                        <Typography sx={{
                          fontSize: '14px',
                          fontWeight: 500
                        }}>
                          {location.data.address}
                        </Typography>
                        <Typography sx={{
                          fontSize: '14px',
                          fontWeight: 300
                        }}>
                          {location.data.city}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                )}

              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container
                direction="column"
                spacing={2}>
                <Grid item>
                  <Box sx={{
                    height: 360,
                    borderRadius: '10px',
                    WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                    overflow: 'hidden'
                  }}>
                    <Map address={currentLocation.data.address} city={currentLocation.data.city} />
                  </Box>
                </Grid>

                <Grid item>
                  <Box sx={{
                    background: '#F5F5F5',
                    borderRadius: '10px',
                    WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                    padding: 2
                  }}>
                    <Grid container
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={{
                        xs: 2,
                        md: 0
                      }}>
                      <Grid item xs={12} md={4}>
                        <Typography sx={{
                          fontSize: '14px',
                          fontWeight: 500
                        }}>
                          {currentLocation.data.address}
                        </Typography>
                        <Typography sx={{
                          fontSize: '14px',
                        }}>
                          {currentLocation.data.city}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography sx={{
                          fontSize: '14px',
                        }}>
                          Telefon: <Typography component="span" sx={{ fontSize: '14px', color: '#710B0E' }}>{currentLocation.data.phone}</Typography>
                        </Typography>
                        <Typography sx={{
                          fontSize: '14px',
                          whiteSpace: 'nowrap'
                        }}>
                          Email: <Typography component="span" sx={{ fontSize: '14px', color: '#710B0E' }}>{currentLocation.data.email}</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography sx={{
                          fontSize: '14px',
                        }}>
                          {currentLocation.data.schedule}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
    <Content doc={page} />
  </>
}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  const locations = data?.locations?.edges.map(({ node }) => node) || {}

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  // Other
  schema = addToSchema(schema, {
    "@context": "https://schema.org",
    "@type": "Dentist",
    "name": "Dental Hygiene Center",
    "description": "Clinicile de stomatologie Dental Hygiene Center",
    "openingHours": "Mo-Sa 9:00-21:00",
    "telephone": "+40773856287",
    "email": "receptie@dentalhygienecenter.ro",
    "address": locations.map(l => ({
      "@type": "PostalAddress",
      "addressLocality": l.data.city,
      "addressRegion": "Sector 1",
      "postalCode": "014463",
      "streetAddress": l.data.address
    })),
    "paymentAccepted": "Cash, Credit Card",
    "priceRange": "$$",
    "image": "https://images.prismic.io/dental-hygiene-center/a2599d70-5262-40fc-9130-6bca5c65da79_banner.png",
  })

  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={pageContext.url} />
}

export const query = graphql`
  query contactPageQuery($lang: String) {
    page: prismicContactPage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {           
        meta_title
        meta_description
        meta_keywords
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          richText
        }
        body {




          ... on PrismicContactPageDataBodyAd {
            id
            slice_type
            items {
              ad_size
              ad {
                document {
                  ... on PrismicAd {
                    id
                    type
                    uid
                    data {
                      title {
                        text
                      }
                      subtitle
                      url
                      left_upper_text {
                        richText
                      }
                      left_lower_text {
                        richText
                      }
                      image {
                        alt
                        gatsbyImageData(height: 255)
                      }
                    }
                  }
                  ... on PrismicBanner {
                    id
                    type
                    uid
                    data {
                      type
                      title {
                        text
                      }
                      subtitle                      
                      button_text
                      url
                      image {
                        alt
                        gatsbyImageData
                      }                      
                    }
                  }
                }
              }
            }
          }



        }       
      }
    }

    locations: allPrismicLocation(
      filter: {lang: {eq: $lang}}
    ) {
      edges {
        node {
          uid
          id
          data {
            address
            city
            coordinates {
              latitude
              longitude
            }
            email
            title {
              text
            }
            phone
            schedule
          }
        }
      }
    }
  }
`

export default ContactPageTemplate