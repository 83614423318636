import React from 'react'
import shortid from 'shortid'
import { Link } from 'gatsby'


import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material'
import HomeIcon from './icons/HomeIcon'


const Breadcrumbs = ({ breadcrumbs }) => {
  const getBreadcrumb = (breadcrumb, index) => {
    if (index + 1 === breadcrumbs.length) {
      return (
        <Typography
          key={shortid.generate()}
          title={breadcrumb.name}
          sx={{
            fontSize: '15px !important',
            fontWeight: 400,
            lineHeight: 1.5,
            whiteSpace: 'nowrap',
            maxWidth: 200,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textDecoration: 'none',
            color: '#000',
            background: '#F5F5F5',
            padding: '4px 8px',
            borderRadius: '6px',
            WebkitMaskImage: '-webkit-radial-gradient(white, black);',
          }}
        >
          {breadcrumb.name}
        </Typography>
      )
    }

    if (breadcrumb.isHome) {
      return (
        <Box
          component={Link}
          key={shortid.generate()}
          to={breadcrumb.path}
          sx={{
            display: 'flex',
            lineHeight: '20px',
            textDecoration: 'none',
          }}
        >
          <HomeIcon sx={{ fontSize: 14 }} />
        </Box>
      )
    }

    return (
      <Box
        component={Link}
        key={shortid.generate()}
        to={breadcrumb.path}
        sx={{
          fontSize: 15,
          lineHeight: 1.5,
          textDecoration: 'none',
          padding: '4px 8px',
          color: '#000',
          fontWeight: 400,
        }}
      >
        {breadcrumb.name}
      </Box>
    )
  }

  return (
    <MuiBreadcrumbs
      aria-label='breadcrumb'
      separator='›'
      sx={{
        lineHeight: 1.5,
        fontSize: '15px !important',
      }}
    >
      {breadcrumbs.map((breadcrumb, index) => getBreadcrumb(breadcrumb, index))}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
