import React from "react";

const Map = ({ address, city }) => {
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <iframe
                title={`${address} ${city}`}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCSZf75me5L6NAPwiFfMqSpYVGeHzDLoFA&q=${address},${city}`}>
            </iframe>
        </div>
    );
}

export default Map